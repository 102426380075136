<template>
  <div class="patientDetail">
    <PatientDetail backRoute="ClinicPatient" />
    <PatientTreatmentList treatmentHistoryDetailRoute="ClinicPatientTreatmentDetail" />
  </div>
</template>

<script>
import PatientDetail from "@/views/components/patients/Detail";
import PatientTreatmentList from "@/views/components/patients/PatientTreatmentList";

export default {
  components: {
    PatientDetail,
    PatientTreatmentList
  }
};
</script>